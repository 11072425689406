<template>
  <div>
    <div class="grid">
      <div
        v-for="(project, idx) in projects"
        :key="idx"
        :class="(idx === 0) ? 'big' : 'small'"
        class="t"
        >
        <router-link
          :to="$i18nRoute({
            name: 'Project', params: { slug: project.slugs[0] }
          })"
        >
          <img
            class="list-hero aspect-ratio-box-inside"
            :src="project.data.hero_img.url"
            :alt="`image for ${$prismic.dom.RichText.asText(project.data.title)}, click to go to the project`"
          />
        </router-link>
        <div class="img-overlay-text">
          <h2>{{ $prismic.dom.RichText.asText(project.data.title) }}</h2>
          <p>{{ project.data.year }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Projects',
  computed: {
    ...mapGetters({
      projects: 'content/getProjects'
    })
  }
}
</script>

<style lang="scss">
@import './../scss/colors.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  // grid-auto-rows: calc(100%);
  grid-gap: 10px 10px;
}
.t {
  position: relative;
  overflow: hidden;
}
.t:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(1333/2000 * 100%);
}
.t.big {
  grid-column: 1 / span 4;
  grid-row: 1 / span 4;
}
.t.small {
  grid-column: auto / span 3;
  grid-row: auto / span 3;
}
.t.small:nth-child(2), .t.small:nth-child(3) {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

.list-hero {
  object-fit: cover;
  min-width: 100%;
  transition: filter .3s ease-in;

}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  min-width: 100%;
}
.img-overlay-text {
  position: absolute;
  top: 25px;
  left: 25px;
  // visibility: hidden;
  opacity: 0;
  color: $color-black;
  transition: opacity .5s ease-in;
  z-index: 2;
  padding: 19px;
  // background-color: $color-white;
}
.img-overlay-text>h2 {
  font-size: 24px;
  font-weight: 300;
}
.img-overlay-text>p {
  padding-top: 8px;
  font-weight: 600;
  font-size: 16px;
  color: $color-grey;
}
.t:hover > .img-overlay-text {
  opacity: 1;
}
.t:hover > a > .list-hero {
  filter: opacity(0.66);
}
.t:hover {
  cursor: pointer;
}
</style>
